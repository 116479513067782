import React, { useReducer } from 'react';
import { Button, Container, Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';

import GetHeartbeats from './Admin/GetHeartbeats';
import GetLotsWithMostRevisions from './Admin/GetLotsWithMostRevisions';
import GetRecentSpecFiles from './Admin/GetRecentSpecFiles';
import GetSiteAlert from './Admin/GetSiteAlert';
import GetQirs from './Admin/GetQir';
import GetSample from './Admin/GetSample';
import SampleManagerApiHealthCheck from './Admin/SampleManagerApiHealthCheck';

const Admin = () => {
    const intialCards = [];
    const [cards, dispatch] = useReducer(
        cardReducer,
        intialCards,
        intialCardsFunc
    );

    function cardReducer (cards, action) {
        switch (action.type) {
            case "setOnRefresh":
            case "setVariant":
                return [...cards.map(card => card.title === action.card.title ? action.card : card)];
            default:
                throw Error("Action not recognised");
        }
    }

    function setRefreshFunction (func, title) {
        let card = cards.find(card => card.title === title)
        card.onRefresh = func;
        dispatch({
            type: "setOnRefresh",
            card: card,
        });
    }

    function setVariant(variant, title) {
        let card = cards.find(card => card.title === title)
        card.variant = variant;
        dispatch({
            type: "setVariant",
            card: card,
        });
    }

    function intialCardsFunc(intialCards) {
        if (intialCards.length <= 0) {
            const adminTitle = "Heartbeat Monitor";
            const recentSpecTitle = "Get Most Recent Spec Files";
            const lotsWithRevisionsTitle = "Lots With The Most Revisions";
            const qirsTitle = "Get QIRS";
            const samplesTitle = "Get Samples";
            const smApiTitle = "SM-API Health Check";
            const siteAlertTitle = "Get Site Alert";
            return [
                { title: adminTitle, description: "Check the latest heartbeat information from L3", component: <GetHeartbeats setVariant={(variant) => setVariant(variant, adminTitle)} setRefreshFunc={(func) => setRefreshFunction(func, adminTitle)} /> },
                { title: recentSpecTitle, description: "View details about the latest spec file import per business unit", component: <GetRecentSpecFiles setVariant={(variant) => setVariant(variant, recentSpecTitle)} setRefreshFunc={(func) => setRefreshFunction(func, recentSpecTitle)} /> },
                { title: lotsWithRevisionsTitle, description: "View the lots with the most lot revisions and purge them", component: <GetLotsWithMostRevisions setVariant={(variant) => setVariant(variant, lotsWithRevisionsTitle)} setRefreshFunc={(func) => setRefreshFunction(func, lotsWithRevisionsTitle)} /> },
                { title: qirsTitle, description: "Make a request to QIRMS to get a QIR", component: <GetQirs setVariant={(variant) => setVariant(variant, qirsTitle)} /> }, 
                { title: samplesTitle, description: "Send a request to SM-API to fetch a sample", component: <GetSample setVariant={(variant) => setVariant(variant, samplesTitle) } /> },
                { title: smApiTitle, description: "Get health information from sample manager api", component: <SampleManagerApiHealthCheck setVariant={(variant) => setVariant(variant, smApiTitle)} /> },
                { title: siteAlertTitle, description: "Check and set the site alert banner", component: <GetSiteAlert setVariant={(variant) => setVariant(variant, siteAlertTitle)} /> },
            ];
        }
        return intialCards;
    }

    function getVariantClasses(variant) {
        switch (variant) {
            case "danger": return "bg-danger text-white";
            case "warning": return "bg-warning text-black";
            default : return null;
        }
    }

    return (
        <Container>
            <Row xs={1} md={2} lg={3} xl={4} >
                {cards.map((card, index) =>
                    <Col className="pt-2" key={`card-${index}`}>
                        <Card className="h-100">
                            <Card.Header as="h5" className={getVariantClasses(card.variant) ?? "bg-white"}>
                                <span className="d-flex">
                                    {card.title}
                                    {card.onRefresh && 
                                        <Button variant="link" className={`${getVariantClasses(card.variant) ?? "text-black"} ms-auto`} onClick={card.onRefresh}>
                                            <FontAwesomeIcon icon={faRefresh}/>
                                        </Button>
                                    }
                                </span>
                            </Card.Header>
                            <Card.Body className="d-flex flex-column">
                                <Card.Subtitle>
                                    {card.description }
                                </Card.Subtitle>
                                <div className="mt-2 flex-fill d-flex flex-column">
                                    {card.component}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>)}
            </Row>
        </Container>
    )
}

export default Admin