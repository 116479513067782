import React, { useState, useEffect } from 'react';
import { Modal, Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { DraggableModalDialog } from './DraggableModalDialog';
import { useHttpUtils } from '../Utilities/useHttpUtils';
import useAlert from '../Alerts/useAlert';


function SetLotStatusDialog({ showDialog, closeDialog, lotNumber, currentLotStatus, currentProcessingStatus, getLotDetail }) {

    const { setAlert } = useAlert();
    const { fetchDataAuthenticated } = useHttpUtils();

    const [lotStatuses, setLotStatuses] = useState();
    const [processingStatuses, setProcessingStatuses] = useState();

    const [lotStatus, setLotStatus] = useState(currentLotStatus);
    const [processingStatus, setProcessingStatus] = useState(currentProcessingStatus);
    const [loading, setLoading] = useState();

    useEffect(() => {
        getLotStatuses();
        getProcessingStatuses();
    }, []);

    const getLotStatuses = () => {
        setLoading(true);
        fetchDataAuthenticated("/api/v1/lotStatus/lot")
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Failed to get lot statuses');
            })
            .then(data => {
                setLotStatuses(data);
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const getProcessingStatuses = () => {
        setLoading(true);
        fetchDataAuthenticated("/api/v1/lotStatus/processing")
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Failed to get processing statuses');
            })
            .then(data => {
                setProcessingStatuses(data);
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const updateLotStatus = async () => {

        let url = `/api/v1/lot/${lotNumber}/status/lotAndProcessing?lotStatus=${lotStatus}&processingStatus=${processingStatus}`;

        setLoading(true);
        await fetchDataAuthenticated(url, 'PUT')
            .then(async response => {
                if (response.ok) {
                    setAlert("success", "Success", `Updated the lot status to ${lotStatus} and processing status to ${processingStatus}`);
                    getLotDetail();
                } else {
                    let errorMessage = await response.text();
                    console.log("Failed to update the lot status: " + errorMessage);
                    throw new Error("Failed to update the lot status.");
                }
            })
            .catch(error => {
                setLoading(false);
                setAlert("danger", "Error", error.message);
            });
    }

    const handleSave = async () => {
        await updateLotStatus();
        setLoading(false);
        closeDialog();
    }

    const onCancel = () => {
        setLotStatus(currentLotStatus);
        setProcessingStatus(currentProcessingStatus);
        closeDialog();
    }

    return (
        <>
            <Modal dialogAs={DraggableModalDialog} show={showDialog} onHide={closeDialog} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Set Lot Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        loading
                            ?
                            <div className="d-flex justify-content-center mb-2">
                                <Spinner animation="border" />
                            </div>
                            :
                            <Form>
                                <Form.Group as={Row} className="mb-2">
                                    <Form.Label column>Lot Status</Form.Label>
                                    <Col sm={8}>
                                        <Form.Select required value={lotStatus} onChange={(e) => setLotStatus(e.target.value)}>
                                            <option>Select...</option>
                                            {lotStatuses &&
                                                lotStatuses.map((ls) => {
                                                    return <option key={`lot-status-option-${ls}`} value={ls}>{ls}</option>
                                                })
                                            }
                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label column>Processing Status</Form.Label>
                                    <Col sm={8}>
                                        <Form.Select required value={processingStatus} onChange={(e) => setProcessingStatus(e.target.value)}>
                                            <option>Select...</option>
                                            {processingStatuses &&
                                                processingStatuses.map((ps) => {
                                                    return <option key={`processing-status-option-${ps}`} value={ps}>{ps}</option>
                                                })
                                            }
                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                            </Form>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onCancel} disabled={loading}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSave} disabled={loading || (lotStatus === currentLotStatus && processingStatus === currentProcessingStatus)}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default SetLotStatusDialog;