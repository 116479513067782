import { createContext, useReducer } from 'react';

const AlertContext = createContext({
    alerts: [],
    setAlert: (variant, header, text) => { },
    dismissAlert: () => { },
});

export const AlertProvider = ({ children }) => {
    const intialAlerts = [];

    const [alerts, dispatch] = useReducer(
        alertReducer,
        intialAlerts
    )

    function setAlert(variant, header, text) {
        dispatch({
            type: "added",
            alert: {
                alertNumber: Math.floor(Math.random() * 10000000),
                variant: variant,
                header: header,
                text: text,
                showAlert: true,
            }
        })
    }

    function dismissAlert(alertNumber) {
        let alert = alerts.find(alert => alert.alertNumber === alertNumber);
        alert.showAlert = false;
        dispatch({
            type: "close",
            alert: alert,
        });
    }

    function alertReducer(alerts, action) {
        switch (action.type) {
            case "added":
                return [action.alert, ...alerts];
            case "close":
                return [...alerts.map(t => t.alertNumber === action.alert.alertNumber ? action.alert : t)];
            default:
                throw Error("Action not recognised");
        }
    }

    return (
        <AlertContext.Provider value={{ alerts, setAlert, dismissAlert }}>
            {children}
        </AlertContext.Provider>
    );
};

export default AlertContext;