import { useEffect, useState } from 'react';
import { Table, Spinner } from 'react-bootstrap';

import { useHttpUtils } from '../../Utilities/useHttpUtils';
import DateAsLocaleString from '../../Utilities/DateAsLocaleString';
import useAlert from '../../Alerts/useAlert';

const GetRecentSpecFiles = ({ setRefreshFunc, setVariant }) => {
    const { setAlert } = useAlert();
    const { fetchDataAuthenticated } = useHttpUtils();
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
        setRefreshFunc(getSpecFiles);
        getSpecFiles();
    }, []);

    const getSpecFiles = async () => {
        setLoading(true);
        await fetchDataAuthenticated("api/v1/admin/specImports", "GET")
            .then(response => {
                if (!response.ok) {
                    return response.json().then(error => Promise.reject(error));
                }
                return response.json();
            })
            .then(data => {
                setError("");
                setFiles(data);
                if (data.length === 0) {
                    setVariant("warning");
                }
                else {
                    setVariant("");
                }
            })
            .catch(error => {
                setFiles();
                setVariant("danger");
                setAlert("danger", error.title, error.detail);
                setError(error.detail);
            })
            .finally(() => setLoading(false));
    }

    const buildTemplate = () => {
        if (loading) {
            return (<Spinner />);
        }
        if (error) {
            return (<div>{error}</div>);
        }

        if (files.length === 0) {
            return (<div>No Files</div>);
        }

        return (
            <Table className="w-100">
                <thead>
                    <tr>
                        <th>Business Unit</th>
                        <th>Message Last Received</th>
                    </tr>
                </thead>
                <tbody>
                    {files && files.map((file, index) =>
                        <tr key={`file-${index}`}>
                            <td>{file.businessUnit}</td>
                            <td><DateAsLocaleString date={file.messageSentAtLocalTime} /></td>
                        </tr>
                    )}
                </tbody>
            </Table>
        )
    }

    return buildTemplate();
}

export default GetRecentSpecFiles;