import React, { FormEvent, useEffect, useState } from "react";
import { Modal, Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { DraggableModalDialog } from './DraggableModalDialog';
import { useHttpUtils } from '../Utilities/useHttpUtils';
import useAlert from '../Alerts/useAlert';
import CommentDialog from '../Dialogs/CommentDialog';

export interface SelectPackageTypeDialogProps {
    showDialog: boolean,
    closeDialog: () => void,
    lotNumber: string,
    recipeName: string, 
    intialPackageType: string,
    getLotDetail: () => void,
}

export interface ChangePackageTypeDTO {
    materialCode: string,
    comment: string,
}

export default function SelectPackageTypeDialog({ showDialog, closeDialog, lotNumber, recipeName, intialPackageType, getLotDetail }: SelectPackageTypeDialogProps) {
    const { setAlert } = useAlert();
    const { fetchDataAuthenticated } = useHttpUtils();
    const { t } = useTranslation();

    const [packageTypes, setPackageTypes] = useState<any[]>([]);
    const [packageType, setPackageType] = useState(intialPackageType);
    const [loading, setLoading] = useState(false);
    const [showCommentDialog, setShowCommentDialog] = useState(false);
    const [formValidated, setFormValidated] = useState(false);

    useEffect(() => {
        const getPackageTypes = () => {
            setLoading(true);
            fetchDataAuthenticated(`/api/v1/recipeToPackageType/?recipeName=${recipeName}`)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error('Failed to get package types');
                })
                .then(data => {
                    setPackageTypes(data);
                })
                .catch(error => {
                    setAlert("danger", "Error", error.message);
                })
                .finally(() => {
                    setLoading(false);
                });
        };
        if (showDialog) {
            getPackageTypes();
        }
    }, [showDialog]);

    const handleSave = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();

        const form = e.currentTarget;

        if (form.checkValidity() === true) {
            setShowCommentDialog(true);
        } else {
            console.warn("Change lot grade form was invalid");
        }

        setFormValidated(true);
    };

    const updatePackageTypeWithComment = async (comment:string) => {
        let url = `/api/v1/lot/${lotNumber}/packageType`;
        let body: ChangePackageTypeDTO = {
            materialCode: packageType,
            comment: comment,
        };

        setLoading(true);
        await fetchDataAuthenticated(url, 'PUT', JSON.stringify(body))
            .then(async response => {
                if (response.ok) {
                    setAlert("success", "Success", `Updated the package type to ${packageType}`);
                    getLotDetail();
                } else {
                    let errorMessage = await response.text();
                    console.log("Failed to update the package type: " + errorMessage);
                    throw new Error("Failed to update the package type.");
                }
                closeDialog();
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const closeDialogAndResetForm = () => {
        setPackageType(intialPackageType);
        setFormValidated(false);
        closeDialog();
    }

    return (
        <>
            <Modal dialogAs={DraggableModalDialog} show={showDialog} onHide={closeDialogAndResetForm} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{t<string>("Dialogs.PackageType.Title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        loading
                            ?
                            <div className="d-flex justify-content-center mb-2">
                                <Spinner animation="border" />
                            </div>
                            :
                            <Form id="selectPackageTypeForm" noValidate validated={formValidated} onSubmit={handleSave}>
                                <Form.Group as={Row} className="mb-2">
                                    <Form.Label column>{t<string>("Dialogs.PackageType.SelectPackageType")}</Form.Label>
                                    <Col sm={8}>
                                        <Form.Select required value={packageType} onChange={(e) => setPackageType(e.target.value)}>
                                            <option>{t<string>("Application.Controls.DefaultSelect")}</option>
                                            {packageTypes &&
                                                packageTypes.map((p) => {
                                                    return <option key={`package-type-option-${p.materialCode}`} value={p.materialCode}>{p.materialCode}</option>
                                                })
                                            }
                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                            </Form>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeDialogAndResetForm} disabled={loading}>
                        {t<string>("Application.Controls.Cancel")}
                    </Button>
                    <Button variant="primary" type="submit" form="selectPackageTypeForm" disabled={loading || (packageType === intialPackageType)}>
                        {t<string>("Application.Controls.Save")}
                    </Button>
                </Modal.Footer>
            </Modal>
            <CommentDialog showDialog={showCommentDialog} closeDialog={() => setShowCommentDialog(false)} onSave={updatePackageTypeWithComment} />
        </>
    );
}