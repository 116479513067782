import React, { useState } from 'react';
import { RequiredDialogInput, DialogInput, DialogCheckbox } from '../../DialogInputs';
import CommonConfigDialog from './CommonConfigDialog';
import { handleInputChange } from '../../Utilities/DialogUtils';
import { useHttpUtils } from '../../Utilities/useHttpUtils';

function BusinessUnitEditDialog({ dialogData, setDialogData, showDialog, closeDialog }) {
    const { fetchDataAuthenticated } = useHttpUtils();
    const [fieldErrors, setFieldErrors] = useState({});

    const saveBusinessUnit = (businessUnit) => {

        let promise = new Promise((resolve, reject) => {
            let url = "api/v1/businessunit";
            if (businessUnit.businessUnitId != null) {
                url += `/${businessUnit.businessUnitId}`;
            }

            fetchDataAuthenticated(url, "POST", JSON.stringify(businessUnit))
                .then(response => response.json())
                .then(data => {
                    if (data.errors !== null && data.errors !== undefined) {
                        reject(data);
                    }
                    resolve(data);
                })
                .catch(error => {
                    alert("there was a problem!" + error);
                });

        });

        return promise;
    };

    const onChange = (e) => {
        handleInputChange(e, dialogData, setDialogData, fieldErrors, setFieldErrors);
    }

    return (
        <CommonConfigDialog title={"Business Unit - " + dialogData.name} dialogData={dialogData} showDialog={showDialog} closeDialog={closeDialog} save={saveBusinessUnit} fieldErrors={fieldErrors} setFieldErrors={setFieldErrors} >
            <RequiredDialogInput controlId="formBusinessUnitName" title="Name" placeholder="Business Unit Name" name="name" value={dialogData.name} error={fieldErrors.name} onChange={onChange} />
            <RequiredDialogInput controlId="formBusinessUnitCode" title="Code" placeholder="Business Unit Short Code" name="code" value={dialogData.code} error={fieldErrors.code} onChange={onChange} />
            <DialogInput controlId="formBusinessUnitADGroupName" title="AD Group Name" placeholder="AD Group Name" name="adGroupName" value={dialogData.adGroupName} error={fieldErrors.adGroupName} onChange={onChange} />
            <DialogCheckbox disabled={dialogData.businessUnitId === null} controlId="formBusinessUnitIsDeleted" title="Deleted?" name="isDeleted" checked={dialogData.isDeleted} error={fieldErrors.isDeleted} onChange={onChange} />
        </CommonConfigDialog>
    );
}

export default BusinessUnitEditDialog;